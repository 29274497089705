$details_padding: 9px 0;

	 .camo-dashboard {
		.app-card {
			background-color: #9d9b96;
			padding: 20px;
			box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
			border-radius: 4px;
			color: $whiteColor;
			min-height: 225px;
			width: 445px;
				.app-card-header {
					display: flex;
						h3 {
							padding-left: 10px;
						}
				}
		}
   .smbc-dasboard-list{
		overflow-x: auto;
			.MuiTableCell-stickyHeader {
				background:#b5bedb;
			}
			.MuiTable-root .MuiTableCell-root{
				border:1px solid #e0e0e0;
				text-align: center;
			}
			.dasboard-list-style{
				min-width: 130px !important
			}
			.average-utils-date{
				min-width: 370px !important
			}
   }
}
